<template>
    <div id="footer" class="flex-colum flex-align-center">
        <div class="footer-top flex-row flex-jusity-space-between">
            <div class="footer-top-nav flex-colum" style="width: 22.88rem; height: 18.25rem">
                <div class="row">
                    <div class="row-title">联系我们</div>
                </div>
                <div class="row flex-row">
                <div class="row-left flex-colum flex-align-start" style="margin-right: 0.48rem">
                    <span>【中国总部】</span>
                </div>
                <div class="row-right" style="margin-top: -0.2rem">
                    <div class="row flex-row flex-align-center" style="margin-bottom: 1rem">
                        <i><my-image :src="require('@/assets/img/home/footer/phone.png')"/></i>
                        <span>联系电话：<a href="tel:4000-972-558">4000-972-558</a></span>
                    </div>
                    <div class="row flex-colum" style="width: 15.25rem">
                        <div class="flex-row"><i style="margin-top: 0;height: 0.75rem"><my-image width="0.75rem" height="0.75rem" :src="require('@/assets/img/home/footer/地址.png')"/></i>
                        <span>公司地址：上海市长宁区金钟路968号凌空</span></div>
                        <span style="margin-left: 1.2rem">SOHO 3号楼201室</span>
                    </div>
                </div>
                </div>
            </div>
            <div class="row flex-row" style="margin-bottom: 1.38rem;margin-top: 2.51rem">
                    <div class="row-left flex-colum flex-align-start" style="width: 5.3rem;margin-right: -0.7rem">
                        <span>【英国】</span>
                    </div>
                    <div class="row-right" style="margin-top: -0.1rem">
                        <div class="row flex-colum flex-align-end" style="margin-left: -0.5rem;margin-bottom: 0.88rem;width: 12.57rem">
                            <div class="flex-row flex-align-center"><i style="width: auto: height:auto;"><my-image width="0.64rem" height="0.63rem" :src="require('@/assets/img/home/footer/phone.png')"/></i>
                            <span>联系电话：<a href="tel: -44-161-932-1110">-44-161-932-1110</a></span></div>
                            <!-- <span><a href="tel: +44 (0)161-247-8520">+44 (0)161-247-8520</a></span> -->
                        </div>
                        <div class="row flex-row flex-align-center" style="margin-bottom: 1rem">
                            <i><my-image :src="require('@/assets/img/home/footer/邮箱.png')"/></i>
                            <span>电子邮箱：<a href="mailto:info@minhandgroup.com">info@minhandgroup.com</a></span>
                        </div>
                        <div class="row flex-row flex-align-center" style="margin-bottom: 0.38rem">
                            <i><my-image :src="require('@/assets/img/home/footer/地址.png')"/></i>
                            <span>公司地址：</span>
                        </div>
                        <div class="row flex-colum flex-jusity-center">
                            <span style="margin-bottom: 0.38rem">Address：Orega King Street, 76 King St, Manchester, M2 4NH</span>
                            <span>Tel：+44 01618175038  </span>
                            <br/>
                            <span>曼城 </span>
                        </div>
                    </div>
            </div>
            <div class="foot-top-nav flex-colum" style="margin-left: 7.56rem">
                <div class="row" style="margin-bottom: 1.25rem">
                    <my-image :drag="false" :src="require('@/assets/img/home/footer/logo.png')"/>
                </div>
                <div class="row flex-row">
                    <div class="flex-colum flex-align-center" >
                        <my-image :drag="false" width="5rem" height="5rem" :src="require('@/assets/img/home/footer/曼汉教育公众号@3x.png')"/>
                        <span style="color: #FFFFFF;font-size: 0.75rem">曼博士公众号</span>
                    </div>
                    <div class="flex-colum flex-align-center" style="width: 5rem;margin-left: 5.44rem">
                        <my-image :drag="false" width="5rem" height="5rem" :src="require('@/assets/img/home/footer/manzhi.jpg')"/>
                        <span style="color: #FFFFFF;font-size: 0.75rem">曼博思公众号</span>
                    </div>

                </div>
                <div style="margin-top: 1.38rem" class="row flex-row">
                    <div class="flex-colum flex-align-center">
                        <my-image :drag="false" width="5rem" height="5rem" :src="require('@/assets/img/home/footer/曼知公众号二维码@3x.png')"/>
                        <span style="color: #FFFFFF;font-size: 0.75rem">曼知公众号</span>
                    </div>
                    <!-- <div class="flex-colum" style="width: 5rem;margin-left: 5.44rem">
                        <my-image :drag="false" width="5rem" height="5rem" :src="require('@/assets/img/home/footer/英国客服.png')"/>
                        <span style="color: #FFFFFF;font-size: 0.75rem">英国客服</span>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="top-bottom-nav default-size ">
            <div class="nav-content flex-row flex-jusity-space-between flex-align-center">
                <span>友情链接:</span>
                <span><a :href="`http://www.minhandedu.com/bssq/#/?pc=`+this.$route.query.pc">博士留学</a></span>
                <span><a :href="`http://www.minhandedu.com/kyjy/#/?pc=`+this.$route.query.pc">科研教育</a></span>
                <span><a :href="`http://www.minhandedu.com/xyfd/#/?pc=`+this.$route.query.pc">学业辅导</a></span>
                <span><a :href="`http://www.minhandedu.com/dsjs/#/?pc=`+this.$route.query.pc">师资力量</a></span>
                <span><a :href="`http://www.minhandedu.com/xsal/#/?pc=`+this.$route.query.pc">学员案例</a></span>
                <span><a :href="`http://www.minhandedu.com/aboutminhandedu/#/?pc=`+this.$route.query.pc">关于我们</a></span>
                <span><a :href="`http://www.minhandedu.com/jiaruwomen/#/?pc=`+this.$route.query.pc">联系我们</a></span>
            </div>
        </div>
        <div class="line">
        </div>
        <div class="footer-bottom">
            <a href="https://beian.miit.gov.cn">Copyright &#169 曼汉教育科技（上海）有限公司. All Rights Reserved. 沪ICP备18021532号-2 网站申明</a>
        </div>
    </div>
</template>

<script>
import MyImage from './MyImage'
export default {
  components: {
    MyImage
  }
}
</script>

<style lang="scss" scoped>
a{
    text-decoration : none;
    color: inherit;
    cursor: pointer;
    user-select: none;
}
a:link{
    text-decoration : none !important;
    color: inherit;
}
a:hover{
    text-decoration : none;
    color: inherit;
}
a:active{
    text-decoration : none;
    color: inherit;
}
span{
    word-break: keep-all;
}
    #footer{
        width: 100%;
        height: 30.75rem;
        background: #363D45;
        .row{
            .row-left{
                width: 4.5rem;
                span{
                    font-size: 0.75rem;
                    color: #fff;
                    line-height: 1.06rem;
                }
            }
            .row-right{
                color: #BCBCBC;
                .row{
                    i{
                        margin-top: 0.15rem;
                        margin-right: 0.38rem;
                        display: inline-block;
                    }
                    span{
                        // display: block;
                        font-size: 0.75rem;
                        color: #BCBCBC;
                        line-height: 1.06rem;
                        word-break: keep-all;
                    }
                }
            }
            .row-title{
                font-weight: Regular;
                font-size: 1rem;
                color: #FFFFFF;
                margin-bottom: 1.06rem;
            }
        }
        .footer-top{
            width: 73.75rem;
            margin-top: 2.31rem;
            .foot-top-nav{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                .row-left{
                    nav{
                        color: #FFFFFF;
                        font-size:1rem;
                        margin-bottom: 0.94rem;
                        cursor: pointer;
                    }
                    span{
                        color: #999999;
                        font-size:0.75rem;
                        margin-bottom: 0.63rem;
                        word-break: keep-all;
                        cursor: pointer;
                    }
                }
                .row-right{
                    margin-left: 7.75rem;
                        word-break: keep-all;
                    nav{
                        color: #FFFFFF;
                        font-size:1rem;
                        margin-bottom: 0.94rem;
                        cursor: pointer;
                    }
                    span{
                        color: #999999;
                        font-size:0.75rem;
                        margin-bottom: 0.63rem;
                        word-break: keep-all;
                        cursor: pointer;
                    }
                }
            }
            .footer-top-nav{
                .row{
                    .row-left{
                        width: 4.5rem;
                        span{
                            font-size: 0.75rem;
                            color: #fff;
                            line-height: 1.06rem;
                        }
                    }
                    .row-right{
                        color: #BCBCBC;
                        .row{
                            i{
                                margin-top: 0.15rem;
                                margin-right: 0.38rem;
                                display: inline-block;
                            }
                            span{
                                // display: block;
                                font-size: 0.75rem;
                                color: #BCBCBC;
                                line-height: 1.06rem;
                                word-break: keep-all;
                            }
                        }
                    }
                    .row-title{
                        font-weight: Regular;
                        font-size: 1rem;
                        color: #FFFFFF;
                        margin-bottom: 1.06rem;
                    }
                }

            }
        }
        .line{
            width: 100%;
            height: 0.06rem;
            background: #4E545B;
            margin-top: 5rem;
        }
        .footer-bottom{
            text-align: center;
            margin-top: 1.81rem;
            color: #BCBCBC;
            font-size: 0.75rem;
            span{
                font-size: 0.75rem;
                color: #BCBCBC;
            }
        }
    }
    .top-bottom-nav{
        .nav-content{
            width: 38.63rem;
            margin-left: 22.5rem;
            font-size: 0.88rem;
            font-weight: 400;
            // color: #D2A4FF;
            color: #A6B6F8;
            line-height: 1.25rem;
            a{
                text-decoration: underline ;
            }
        }
    }
</style>
